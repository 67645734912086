import React, { useState, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from "@mui/material/styles";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { theme } from "../lib";
import { initializeFirebase } from '../../shared/firebase';

const AppWrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
);

const StripeProvider = ({ stripePromise, children }) => {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export function createAndRenderApp(MainComponent) {
  document.addEventListener("DOMContentLoaded", async () => {
    const container = document.getElementById("react-root");

    if (container) {
      try {
        // Fetch configuration
        const response = await axios.get('/api/config');
        const config = response.data;

        console.log("Config received in AppWrapper:", config);

        // Set up CSRF token for axios
        axios.defaults.headers.common['X-CSRF-Token'] = config.csrfToken;

        // Initialize Firebase with the fetched config
        initializeFirebase(config);

        // Initialize Stripe with the fetched public key
        const stripePromise = loadStripe(config.stripe.publicKey);

        const root = createRoot(container);
        root.render(
          <AppWrapper>
            <StripeProvider stripePromise={stripePromise}>
              <MainComponent config={config} />
            </StripeProvider>
          </AppWrapper>
        );
      } catch (error) {
        console.error('Failed to initialize app:', error);
      }
    } else {
      console.error('Could not find element with id "react-root"');
    }
  });
}

export { AppWrapper };
